import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ComponentStyles from '../brand-styles/components/RecipeSearchWidget.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import search from '../images/search.png';
import RegularButton from './RegularButton';
import Heading from './Heading';
import { breakpoints } from '../style-utilities/variables';
import { colors } from '../../src_american-beauty/style-utilities/variables';

const Div = styled.div`
  background-image: ${props => `url(${props.bg})`};
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .title-search {
    font-family: Poppins, sans-serif;
    color: rgb(190, 13, 52);
    font-size: 32px;
    margin: .67em 0;
    font-weight: bold;
  }
  .opacity-layer {
    height: 100%;
    width: 100%;
    padding: 100px 0;
  }
  h1 {
    color: white;
    font-size: 32px;
  }
  .search-form {
    width: 50%;
    border: 1px solid white;
    background: white;
    margin-bottom: 10px;
    background-image: url(${search});
    background-size: 30px 30px;
    background-repeat: no-repeat;
    padding: 14px 12px 10px 46px;
    background-position-y: center;
  }
  .search-form:placeholder {
    color: white;
  }
  .multiple-button-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
    .button,
    .all-recipes {
      text-decoration: none;
      margin: 0 5px;
      padding-left: 20px;
      padding-right: 20px;
      height: 40px;
      background: white;
      color: ${colors.primary};
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: italic;
      font-size: 14px;
      margin-bottom: 8px;
      a {
        text-decoration: none;
        font-size: 14px;
      }
    }
    .button:hover {
      background-color: #334d81;
      transition: background-color 0.3s ease-out;
    }
    .all-recipes:hover {
      background-color: #dfdfdf;
      transition: background-color 0.3s ease-out;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    .search-form,
    .multiple-button-section {
      width: 90%;
    }
    .multiple-button-section {
      margin: auto;
      text-align: justify;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const RecipeSearchWidget = ({
  background,
  link1,
  clicked,
  changeHandler,
  children,
  ...remainProps
}) => {
  const terms = [
    { name: 'Casserole', slug: 'casserole' },
    { name: 'Lunch', slug: 'lunch' },
    { name: 'Vegetarian', slug: 'vegetarian' }
  ];
  return (
    <Div {...remainProps} className="search-widget" bg={background}>
      <div className="opacity-layer">
        <div class="title-search">Search For Recipes</div>
        <form onSubmit={clicked}>
          <input
            className="search-form"
            placeholder="Search for recipes"
            onChange={changeHandler}
          />
        </form>
        <div className="multiple-button-section">
          {terms.map(item => {
            return (
              <div key={item.slug}>
                <Link
                  to={`/recipe-search?items=${item.slug}`}
                  key={item.slug}
                  className="button"
                >
                  {item.name}
                </Link>
              </div>
            );
          })}
          <div className="all-recipes">
            <Link to="/recipe-search">All Recipes</Link>
          </div>
        </div>
      </div>
      {children}
    </Div>
  );
};

export default RecipeSearchWidget;
