import { css } from 'styled-components';
import { fonts, colors } from '../../style-utilities/variables';
import browseBackground from '../../images/side_farfale.jpg';

export default css`
  /* background-image: url(${search}); */
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  color: white; 
  h1 { 
    font-family: ${fonts.primary}, sans-serif;
    color: ${colors.primary};
  }

  form {
    position: relative;
    display: inline-block;
    width: 50%;

    .search-form {
      width: 70%;
      padding-left: 45px;
    }
  }
  .multiple-button-section {
    .button {
      background: ${colors.primary};
      color:${colors.white};
    
      &:hover {
        background: rgba(190,13,52, 0.5);
        
      }
    }
    a {
      color: ${colors.primary};
    }
    .all-recipes {
      &:hover {
        background: ${colors.primaryHover};
        color: ${colors.white};
        a {
          color: ${colors.white};
        }
      }
    }
  }
`;
