import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import { breakpoints, colors } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import PagesHero from '../components/PagesHero';
import RecipeSearchWidget from '../components/RecipeSearchWidget';
import StyledLink from '../components/StyledLink';
import ComponentStyles from '../brand-styles/pages/recipe-categories.styles';

import dishType from '../images/pasta-white.png';
import cookingMethod from '../images/method-white.png';
import main from '../images/ingredient-white.png';
import meal from '../images/meal-white.png';
import ready from '../images/clock-white.png';
import sauce from '../images/sauce-white.png';

const Div = styled.div`
  .InnerBox {
    display: -ms-inline-flexbox;
    .title {
      margin: 0 auto;
      background: rgb(
        204,
        204,
        204
      ); /* Fallback for older browsers without RGBA-support */
      background: rgba(204, 204, 204, 0.7);
      z-index: 0;
      margin-bottom: 20px;
      h1 {
        margin: 0;
        padding: 15px 20px;
        color: ${colors.secondary};
        font-size: 40px;
        z-index: 4;
      }
      p {
        margin: 0;
        padding: 15px 20px;
      }
    }
  }
  .search {
    display: none;
    position: absolute;
    margin: 3rem;
    @media (min-width: ${breakpoints.tablet}) {
      display: block;
    }
  }
  h2 {
    display: block;
    margin: 20px 0;
    text-align: center;
    color: gray;
  }

  h2 {
    margin: 40px auto;
    font-size: 36px;
    color: #163571;
  }
  h3 {
    font-weight: normal;
    font-size: 20px;
    font-weight: bold;
    color: ${colors.primary};
  }

  .categoryWrapper {
    margin-bottom: 40px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    .hero-all-recipes {
      display: none;
    }
    .hero {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ${ComponentStyles}
`;

const CategorySections = styled.div`
  z-index: 1;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  h2 {
    color: ${colors.primary};
  }
  @media (min-width: ${breakpoints.tablet}) {
    .categoryWrapper {
      display: -ms-grid;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;
      grid-gap: 30px;
      display: -ms-grid;
      -ms-grid-columns: 30px 1fr 30px 1fr 30px 1fr 30px;
      -ms-grid-rows: 1fr 30px 1fr;

      .section-1 {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }
      .section-2 {
        -ms-grid-row: 1;
        -ms-grid-column: 4;
      }
      .section-3 {
        -ms-grid-row: 1;
        -ms-grid-column: 6;
      }
      .section-4 {
        -ms-grid-row: 3;
        -ms-grid-column: 2;
      }
      .section-5 {
        -ms-grid-row: 3;
        -ms-grid-column: 4;
      }
      .section-6 {
        -ms-grid-row: 3;
        -ms-grid-column: 6;
      }
    }
    text-align: left;
    .section-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    .section-2 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .section-3 {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }
    .section-4 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
    .section-5 {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }
    .section-6 {
      -ms-grid-row: 3;
      -ms-grid-column: 5;
    }
  }
  a {
    text-decoration: none;
  }
`;

const Section = styled.div`
  margin: 0 auto;
  .imgWrapper {
    background: url(${props =>
      props.backgroundImage ||
      'https://rivianaapi.dev.ansira.io/minute-rice/wp-content/uploads/sites/2/2018/12/fa-image.png'});
    background-color: ${colors.primary};
    border: 3px solid ${colors.primary};
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    width: 150px;
    @media (max-width: ${breakpoints.tablet}) {
      margin: 30px auto 0;
    }
  }
  p {
    color: ${colors.bodyText};
  }
`;

class RecipeCategory extends Component {
  state = {
    searchTerm: ''
  };

  taxonomies = {
    ingredient: this.props.data.allWordpressWpMainIngredient.edges,
    meal: this.props.data.allWordpressWpMealCourse.edges,
    cooking: this.props.data.allWordpressWpCookingMethod.edges,
    dish: this.props.data.allWordpressWpDishType.edges,
    ready: this.props.data.allWordpressWpReadyIn.edges,
    sauce: this.props.data.allWordpressWpSauce.edges
  };

  sections = [
    {
      id: 'section-1',
      title: 'Main Ingredient',
      content: this.taxonomies.ingredient,
      img: main
    },
    {
      id: 'section-2',
      title: 'Meal Part',
      content: this.taxonomies.meal,
      img: meal
    },
    {
      id: 'section-3',
      title: 'Cooking Method',
      content: this.taxonomies.cooking,
      img: cookingMethod
    },
    {
      id: 'section-4',
      title: 'Dish Type',
      content: this.taxonomies.dish,
      img: dishType
    },
    {
      id: 'section-5',
      title: 'Ready In',
      content: this.taxonomies.ready,
      img: ready
    },
    {
      id: 'section-6',
      title: 'By Sauce',
      content: this.taxonomies.sauce,
      img: sauce
    }
  ];

  onChangeHandler = event => {
    const value = event.target.value;
    this.setState({ searchTerm: value });
  };

  submitHanler = e => {
    e.preventDefault();
    this.setState(() => {
      navigate(`/recipe-search?includes=${this.state.searchTerm}`);
    });
  };

  render() {
    const heroImage = this.props.data.wordpressPage.acf.hero_image.source_url;
    const { search_widget_image } = this.props.data.wordpressPage.acf;
    return (
      <Div>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <div className="search">
          <StyledLink link="/recipe-search">See All Recipes</StyledLink>
        </div>
        <PagesHero className="hero-section" image={heroImage}>
          <div className="InnerBox">
            <div className="title">
              <h1>Recipe Categories</h1>
            </div>
          </div>
        </PagesHero>
        <CategorySections>
          <h2>All Categories</h2>
          <div className="categoryWrapper">
            {this.sections.map((section, index) => {
              return (
                <Section
                  key={section.id}
                  className={section.id}
                  backgroundImage={section.img}
                >
                  <div className="imgWrapper" />
                  <h3>{ReactHtmlParser(section.title)}</h3>
                  {section.content.map(content => {
                    return (
                      <Link
                        key={content.node.wordpress_id}
                        to={`/recipe-search?items=${content.node.slug}`}
                      >
                        <p>{ReactHtmlParser(content.node.name)}</p>
                      </Link>
                    );
                  })}
                </Section>
              );
            })}
          </div>
        </CategorySections>
        <RecipeSearchWidget
          background={search_widget_image.source_url}
          changeHandler={this.onChangeHandler}
          clicked={this.submitHanler}
        />
      </Div>
    );
  }
}

export default RecipeCategory;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "recipe-categories" }) {
      slug
      acf {
        hero_image {
          source_url
        }
        search_widget_image {
          source_url
        }
      }
      yoast_head_json {
        title
        description
      }
    }
    allWordpressWpMainIngredient {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpMealCourse {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpCookingMethod {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpDishType {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpReadyIn {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpSauce {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
  }
`;
